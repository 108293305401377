const content = [
  {
    key: 'acesso-remoto',
    name: 'Acesso Remoto',
    files: [
      {
        name: 'AnyDesk',
        url: 'https://anydesk.com/pt/downloads'
      },
      {
        name: 'RustDesk',
        url: 'https://rustdesk.com/download'
      },
      {
        name: 'TeamViewer SCONTTI',
        url: 'https://get.teamviewer.com/scontti'
      },
      {
        name: 'TeamViewer 13',
        url: 'https://www.teamviewer.com/pt-br/download/previous-versions/previous-version-13x'
      }
    ]
  },
  {
    key: 'teste-velocidade',
    name: 'Teste de Velocidade',
    files: [
      {
        name: 'Fast',
        url: 'https://fast.com/pt'
      },
      {
        name: 'nPerf',
        url: `https://scontti.com/nperf`
      },
      {
        name: 'SpeedTest',
        url: `https://scontti.com/speedtest`
      }
    ]
  }
]

export default content
