import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import DownloadList from '../components/DownloadList'
import { Wrapper } from '../styles/layout'

export default function Downloads() {
  return (
    <Layout>
      <Seo title="Suporte" />
      <Wrapper>
        <DownloadList />
      </Wrapper>
    </Layout>
  )
}
