import React, { useState } from 'react'
import { Downvote } from 'styled-icons/boxicons-solid'

import { Container, Header as Title, Group, List, Item } from './styles'
import content from './content'

import Header from '../../styles/Header'

export default function DownloadList() {
  const [selected, setSelected] = useState(null)

  function handleClick(group) {
    group === selected ? setSelected(null) : setSelected(group)
  }

  return (
    <>
      <Header as="h2" content="Arquivos para suporte" />
      <Container>
        {content.map(({ key, name, files }) => (
          <Group key={key}>
            <Title>
              <button type="button" onClick={() => handleClick(key)}>
                {name}
              </button>
            </Title>
            <List show={selected === key}>
              {files.map(({ name, url }) => (
                <Item key={name}>
                  <a href={url} target="_blank" rel="noreferrer">
                    {name}
                    <Downvote />
                  </a>
                </Item>
              ))}
            </List>
          </Group>
        ))}
      </Container>
    </>
  )
}
