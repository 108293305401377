import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
`

export const Header = styled.div`
  button {
    border: none;
    background: none;
    outline: none;
    line-height: 50px;
    padding: 0 20px;
    width: 100%;
    text-align: left;
    font-size: 1.1rem;
    cursor: pointer;
  }
`

export const Group = styled.div`
  border-bottom: 1px solid #cecece;

  :last-child {
    border: none;
  }
`

export const List = styled.div`
  border-top: 1px solid #cecece;
  padding: 15px;
  display: ${props => (props.show ? 'block' : 'none')};
`

export const Item = styled.div`
  margin: 10px;
  border-bottom: 1px solid #cecece;
  padding: 5px 0;

  a {
    svg {
      width: 20px;
      float: right;
    }
  }
`
